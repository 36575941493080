import { useState,useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"


import TableOfContents from "./TableOfContents/index"
import BottomTray from "./AssignResources/bottomTray"
import LeftSlide from "../../../components/LeftSlide"
import AssignToUsers from "../../../components/Models/assignToUsers"
import ExpandedView from "./AssignResources/expandedView";
import API from "../../../../API/assignments"
import ResourceIndexBySection from "./resourceIndexBySection"
import generatePdfFromImages from "./printSelectedResource";
import { localDeployment } from "../../../../API/config"

export default function ResourceIndex(props) {
    const {
        navHeight,
    } = props;

    const [worksheets, setWorksheets] = useState([])
    const [selectAllWorksheets, setSelectAllWorksheets] = useState(false);
    const [assignmentModel, setAssignmentModel] = useState(false);
    const [hideTray, setHideTray] = useState(true);
    const [trayHeight, setTrayHeight] = useState(0);
    const [paddingBottom, setPaddingBottom] = useState(0);
    const [contentHeight, setContentHeight] = useState('calc(100% - '+navHeight+')');
    const [showExpanded, setShowExpanded] = useState(false)

    const token = useSelector(state=>state.token)
    const currentResource = useSelector(state=>state.currentResource)
    const bookmark = useSelector(state=>state.bookmark)
    const screen = useSelector(state=>state.screen)
    const myGroup = useSelector(state=>state.myGroup)
    const allowPrint = useSelector(state=>screen.indexOf('vendor')<0?state.myInfo.allow_print:false)
    const srcGroup = useSelector(state=>screen.indexOf('vendor')<0?state.srcGroup:false)
    
    const dispatch = useDispatch();
    useEffect(()=>{
      setSelectAllWorksheets(true)
    },[bookmark])

    const removeWorksheets = (item) => {
      if(item!=='all'){
        setWorksheets(items=>
            [...items].filter(x=>x!==item)
        )
        worksheets.length<=1&&setTrayHeight(0)
      }else{
        setWorksheets([]);
        setTrayHeight(0)
      }

    }
    const sendToStudents = (data) => {
      API.postAssignment(token, 
        {
          'groupId': srcGroup._id,
          'userIds': [...data],
          'type':'assignment',
          'origin':currentResource._id,
          "title": currentResource.Title??'',
          "level": currentResource.Level??'',
          "subject": currentResource.Subject??'',
          "category": currentResource.Category??'',
          'worksheetsSrc':worksheets
        } 
      )
      .then(()=>{
        setAssignmentModel(false);
        setWorksheets([]);
        setTrayHeight(0)
        setSelectAllWorksheets(true)
        dispatch({ type: 'SET_NOTIFICATION', payload: {
          show: true,
          code: 200,
          message: 'Assignments sent.'
      }})        
      })
      .catch(err=>console.log(err))
    }


    useEffect(()=>{
      if(trayHeight){
        setContentHeight('calc(100vh - '+trayHeight+' - '+navHeight+')')
      }else{
        setContentHeight('calc(100vh - '+navHeight+')')
      }
    },[trayHeight, navHeight])
    return (
    <div 
          className="h-100"
        >
          {assignmentModel&&
          <AssignToUsers
              setAssignmentModel={setAssignmentModel}
              token={token}
              sendToStudents={sendToStudents}
          />}
          {<LeftSlide
            style={{height:contentHeight}}
            width={'30%'}
            SlideInContent={TableOfContents}
          />}
          <div>
            {worksheets.length>0&&<BottomTray
                worksheets={worksheets}
                hideTray={hideTray}
                trayHeight={trayHeight}
                setTrayHeight={setTrayHeight}
                selectAllWorksheets={selectAllWorksheets}
                setAssignmentModel={setAssignmentModel}
                setHideTray={setHideTray}
                removeWorksheets={removeWorksheets}
                setWorksheets={setWorksheets}
                setClearTray={setWorksheets}
                setSelectAllWorksheets={setSelectAllWorksheets}
                print={(allowPrint||myGroup==="1")&&localDeployment?generatePdfFromImages:false}
            />}
          </div>
          {currentResource.Chapters&&<ResourceIndexBySection
            style={{paddingBottom:trayHeight, height:'calc(100% - '+navHeight+')'}}
            token={token}
            worksheets={worksheets}
            worksheetsIndex={currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets??[]}
            isUploadResources={currentResource.Type==="Upload"}
            
            setWorksheets={setWorksheets}
            removeWorksheets={removeWorksheets}
            setShowExpanded={setShowExpanded}
          />}
            {showExpanded&&
                <ExpandedView
                    worksheet={'vendors-upload/'+currentResource._id+"/"+showExpanded+"?w=150"}
                    solution={currentResource.Solutions.length>0?'vendors-upload/'+currentResource.Solutions[0]._id+"/"+showExpanded+"?w=150":false}
                    setShowExpanded={setShowExpanded}
                    category={currentResource.Category}
                    navHeight={navHeight}
                />
            }
        </div>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { useAccordionButton, AccordionContext, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import LeftSlideToggleIcon from '../leftSlideToggleIcon';
import UpdateObjectModel from '../Models/updateObjectModel';
import DeleteObjectModel from '../Models/deleteObjectModel';
import ExpandToggle from './expandedToggle';
export default function Row (props) {
    const {
        item,
        target,
        eventKey,
        style,
        tableEntryTemplates,
        onRowUpdate
    } = props
    const dispatch = useDispatch();
    const { activeEventKey } = useContext(AccordionContext);
    const setExpand = useAccordionButton(eventKey);
    const currentResource = useSelector(state=>state.currentResource);
    const srcGroup = useSelector(state=>state.srcGroup);
    const myGroup = useSelector(state=>state.myGroup);
    const tgtUser = useSelector(state=>state.tgtUser);
    const navigate = useNavigate();

    return (
    <div
        className='d-flex text-start w-100 m-0'
        style={style}
    >
        {tableEntryTemplates.map((col, index)=>
        <div
            className={(col.col?'col-'+col.col:'col')}
            key={index}
        >
            {{
            '':
            <ExpandToggle
                setExpand={setExpand}
                hidden={eventKey} 
                expanded={activeEventKey === eventKey}
            />,
            'conditionalComponent':
            <div
                className={"secondary-font col-"+col.col}
                key={index}
            >
                {col.conditionalComponent&&<col.conditionalComponent data={item}/>}
            </div>,
            'actions':
            col.actions.length>1?
                col.actions.map((action, index)=>
                <div 
                    key={index}
                    className='col-xl-5 col-8 mx-auto'
                >
                    <LeftSlideToggleIcon
                        variant={{
                            'PUT':'primary',
                            'DELETE':'danger',
                        }[action.verb]}
                        label={action.name}
                        icon={action.icon}
                        onClick={()=>{
                            dispatch({ type: {
                                'group':'SET_TGT_GROUP',
                                'user':'SET_TGT_USER',
                                'resource':'SET_CURRENT_RESOURCE'
                            }[target],
                            payload: item})
                        }}
                        slideInContent={
                        {
                            'PUT':
                            ()=><UpdateObjectModel
                                model={target}
                                submit={action.execute}
                                reload={onRowUpdate}
                            >
                                {action.objectDetails}
                            </UpdateObjectModel>,
                            'DELETE':
                            ()=><DeleteObjectModel
                                model={target}
                                submit={action.execute}
                                reload={onRowUpdate}
                            />,
                        }[action.verb]}
                    />
                </div>
                )
                :
                <Button
                    className='lighter-hover pointer border-0'
                    variant='link'
                    disabled={item._id===srcGroup._id||item._id===tgtUser._id}
                    onClick={async ()=>{
                        await dispatch({ type: {
                            'group':'SET_SRC_GROUP',
                            'user':'SET_TGT_USER',
                            'resource':'SET_CURRENT_RESOURCE'
                        }[target], payload: item});
                        if (currentResource._id){
                            await dispatch({ type: 'SET_SCREEN', payload: 'assignment/editTool' })
                            await navigate('/epenApp/editTool')
                        }
                        target==='group'&&await dispatch({ type: 'RESET_UPDATABLE'});
                        await dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                    }}
                >
                    <FontAwesomeIcon
                        className='text-primary'
                        icon={faSignIn}
                    />
                </Button>
            }[col.key]
            ||
            <p className={'m-0 ms-2 px-3 text-start '+(index!==1?"text-center":undefined)}>
                {/* Hard code sync status for now. This will need to be updated when row has more condition */}
                {(
                col.showCount?
                    col.altKey&&item[col.altKey]==="true"?
                    "sync"
                    :    
                    item[col.key].length
                :
                item[col.key]
                ) + (target==='group'&&myGroup._id===item['_id']&&col.key==='name'?' (Main)':'')}
            </p>}
        </div>
        )}
    </div>
    )
}
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Nav, Row, Tab, Form, Button } from "react-bootstrap";
import Worksheet from "../../components/Worksheet";
import API from "../../../API/groups";
import LeftSlideToggleIcon from "../../components/leftSlideToggleIcon";
import DeleteObjectModel from "../../components/Models/deleteObjectModel";

export default function Setting (props) {
    const dispatch = useDispatch();
    const [banner, setBanner] = useState(false)
    const token = useSelector(state=>state.token)
    const srcGroup = useSelector(state=>state.srcGroup)
    const keys = [{
        title: "Banner",
        content: 
        <div className="row p-0">
            <div className="col-xl-5 col-12 text-start">
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload banner</Form.Label>
                <Form.Control 
                    type="file"
                    onChange={(e)=>setBanner(e.target.files[0])
                }/>
            </Form.Group>
                <p>Banner uploades and applies here will be displayed on all resources that categorizes as supplemental workbook. Ensure a dimension of 937 x 274px at 72 DPI to optimal image quality.</p>
                <Button 
                    className="me-1" 
                    disabled={!banner}
                    onClick={()=>{
                        dispatch({type: "SHOW_PRELOAD", payload: true})
                        API.addGroup(token, {_id:srcGroup._id, banner:banner}).then((res)=>{
                            setBanner(false)
                            dispatch({type: "SET_SRC_GROUP", payload: res.data[0]})
                            dispatch({ type: 'SET_NOTIFICATION', payload: {
                                show: true,
                                code: 200,
                                message: 'Banner updated!'
                            }})
                            dispatch({type: "SHOW_PRELOAD", payload: false})
                        })
                    }}    
                >Apply</Button>
                <LeftSlideToggleIcon
                    outline
                    variant="danger"
                    disabled={!srcGroup.banner}
                    label={"Remove banner"}
                    slideInContent={()=>(
                        <DeleteObjectModel
                            model={"group"}
                            submit={()=>
                                API.updateGroup(token, {_id:srcGroup._id, banner:srcGroup.banner}).then((res)=>{
                                    dispatch({ type: 'SET_NOTIFICATION', payload: {
                                        show: true,
                                        code: 200,
                                        message: 'Banner removed sucessfully!'
                                    }})
                                    setBanner(false)
                                    dispatch({type: "SET_SRC_GROUP", payload: res.data[0]})
                            })}
                        />
                    )}
                />
            </div>
            <div className="col-xl-7 col-12">
                <Worksheet
                    className="w-100 drop-shadow my-3"
                    category="sample"
                    src="/assets/sample.jpg"
                    previewBanner={banner}
                    bannerUploaded={
                        banner?
                            URL.createObjectURL(banner)
                            :
                            srcGroup.banner
                    }
                />
            </div>
        </div>
    }]
    return (
    <div 
        className="row align-items-center justify-content-center w-100 h-100 p-0"
    >
        <div
            className="bg-white drop-shadow rounded row p-0"
            style={{height:'90%', width:'80%', zIndex:100}}
        >
        <Tab.Container
            defaultActiveKey={keys[0].title}
            className="h-100"
        >
        <Row
            className="p-0 h-100"
        >
            <Col className="col-auto py-2" style={{background: 'rgba(0,0,0,0.1)'}}>
            <Nav variant="pills" className="flex-column">
                {keys.map((key, index)=>
                <Nav.Item key={index}>
                    <Nav.Link eventKey={key.title} className="py-1">{key.title}</Nav.Link>
                </Nav.Item>
                )}
            </Nav>
            </Col>
            <Col className="h-100">
            <Tab.Content className="py-3 h-100 overflow-hidden">
                {keys.map((key, index)=><Tab.Pane key={index} eventKey={key.title}>{key.content}</Tab.Pane>)}
            </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
        </div>
    </div>
    )
}
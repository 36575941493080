import { isMobile } from "react-device-detect";
import LeftSlideToggleIcon from "../leftSlideToggleIcon"
import NewObjectModel from "../Models/newObjectModel";
import { useSelector } from "react-redux";

export default function Table (props) {
    const {
        children,
        tableEntryTemplates,
        style,
        target,
        tableMenuActions,
        className,
        onScroll,
        setIsSubmitted
    } = props;
    const token = useSelector(state=>state.token)
    const menuActions = tableMenuActions?
        tableMenuActions.map((action, index)=>
        <LeftSlideToggleIcon
            key={index}
            label={action.name}
            icon={action.icon}
            outline={true}
            slideInContent={
                {'ADD':
                ()=>
                <NewObjectModel
                    token={token}
                    model={target}
                    submit={(e)=>{
                        action.execute(e)
                        setIsSubmitted(true)
                    }}
                    
                >
                    {action.objectDetails}
                </NewObjectModel>
                }[action.verb]
            }
        />)
    :
    <></>


    return (
    <div
        className={"rounded mx-auto px-2 overflow-y-scroll bg-light position-relative text-start " +className}
        onScroll={onScroll}
        style={{height:style?style.height:'90%', width:(!isMobile?(style&&style.width)??'80%':'90%'), zIndex:100}}
    >
        <div className="sticky-top bg-light pt-2">
            {menuActions}
            <div className="bg-secondary mt-2 w-100 row justify-content-around rounded px-3">
            {tableEntryTemplates&&tableEntryTemplates.map((item, index)=>
                <p 
                    key={index} 
                    className={(item.col?'col-'+item.col:'col') + ' text-start text-white my-2 ' +(index!==1?"text-center":"")}
                >
                    {item.label}
                </p>
            )}
            </div>
        </div>
        {children}
    </div>
    )
}
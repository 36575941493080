import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WorksheetContainer from "../../components/Worksheet/container";
import AddResource from "./addResource";
import DateFilter from "./dateFilter";


export default function LoadResources (props) {
    const{
        type,
        pageLoad,
        groupResources,
        setGroupResources,
        resources,
        setResources,
        showPricing,
        
        resourcesIndex,
        groupResourcesIndex,
        resourceAdd,
        actions,
    } = props
    const dispatch = useDispatch();
    const srcGroup = useSelector(state=>state.srcGroup)
    const tgtUser = useSelector(state=>state.tgtUser)
    const portal = useSelector(state=>state.portal)
    const screen = useSelector(state=>state.screen)
    const token = useSelector(state=>state.token)
    const filter = useSelector(state=>state.filters).filter(item=>item.name==='resources')[0].value
    const [filteredDate, setFilteredDate] = useState([])
    const [currentDate, setCurrentDate] = useState(false)
    const loadResources = async () => {
        if(resourcesIndex){
            await dispatch({type: 'SHOW_PRELOAD', payload: true});
            await resourcesIndex(token, filter, pageLoad)
            .then(response=>{
                pageLoad===1?
                    setResources(response.data)
                    :
                    setResources([...resources, ...response.data]);
            })
            .catch(err=>{
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 300,
                    message: 'Failed to load personal resources.'
                }})
                console.log(err)
            });
        }
        if(groupResourcesIndex){
            await groupResourcesIndex(token, srcGroup._id, filter, pageLoad, tgtUser?tgtUser._id:null)
            .then(response=>{
                pageLoad===1?
                    setGroupResources(response.data)
                    :
                    setGroupResources([...groupResources,...response.data]);
                let dates = response.data.length>0?
                response.data.map(item=>({
                    Users: item.Users,
                    date: item.AssignedOn
                })):[]
                type==='assignment'&&
                setFilteredDate(pageLoad===1?dates.sort((a, b)=>{return b.date-a.date}):[...filteredDate, ...dates].sort((a, b)=>{return b.date-a.date}))
            })
            .catch(err=>{
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 500,
                    message: 'Failed to load group resources.'
                }})
                    console.log(err)
            });
        }
        await dispatch({type: 'SHOW_PRELOAD', payload: false});
    }

    useEffect(()=>{
        portal==='EpenApp'&&dispatch({ type: 'SET_SCREEN', payload: 'resources/'+type});
    },[type])
    useEffect(()=>{
        ((!filter.input&&token)||screen)&&loadResources()
    },[srcGroup, filter, tgtUser, pageLoad, type, token])
    return (
    <div 
        className="d-flex flex-wrap"
    >
        <div className="w-100 d-flex mb-3">
            <AddResource
                resourceAdd={resourceAdd}
                loadResources={loadResources}
            />
            {type==='assignment'&&([...resources, ...groupResources].length>0)&&
                <DateFilter
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    dates={filteredDate.filter(item=>item.Users.some(userId=>tgtUser._id?userId===tgtUser._id:true)).map(items=>items.date)}
                />
            }
        </div>
        {([...resources, ...groupResources].length>0)?
        [...resources, ...groupResources]
        .filter(resource=>srcGroup.sync_with_root==='false'?
            srcGroup.inventory_items.some(item=>item===resource._id)
            :
            resource
        )
        .filter(resource=>(type!=='inventory'&&filter.input!=='')?
            resource.Title.toLowerCase().indexOf(filter.input)>=0
            :
            resource
        )
        .filter(resource=>type==='assignment'?
            (resource.AssignedOn>=currentDate&&resource.AssignedOn<=currentDate+86400000)
            :
            resource)
        .map((item, index)=>
        <div 
            className="col-auto"
            key={index}
        >
            <WorksheetContainer
                key={index}
                item={item}
                type={type}
                actions={actions}
                loadResources={loadResources}
                showPricing={showPricing}
            />
        </div>
        )
        :
        <div className="col-12 d-flex align-items-center justify-content-center">
            <h3>No {type} item found</h3>
        </div>}
    </div>
    );
}
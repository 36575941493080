import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadGroups } from "../../screen/Groups/groupAction";
import { loadUser } from "../../screen/Users/usersAction"
import RowContainer from "../Table/rowContainer";
import LeftSlideToggleIcon from "../leftSlideToggleIcon";
import Searchbar from "./searchbar";

export default function ObjectsNavigation ({type}) {
    const dispatch = useDispatch();
    const token = useSelector(state=>state.token);
    const navigate = useNavigate();
    const srcGroup = useSelector(state=>state.srcGroup);
    const myGroup = useSelector(state=>state.myGroup);
    const tgtUser = useSelector(state=>state.tgtUser);
    const currentResource = useSelector(state=>state.currentResource);
    const tableEntryTemplates = type==="group"?
    [
        {
            'label': '',
            'key': '',
            'col':'auto',
            'actions':[]
        },
        {
            'label': 'Name',
            'key': 'name',
            'col':'',
            'actions':[]
        },
        {
            'label': 'Actions',
            'col':'auto',
            'key': 'actions',
            'actions':[]
        },
    ]
    :
    [
        {
            'label': '',
            'key': '',
            'col':'auto',
            'actions':[]
        },
        {
            'label': 'Name',
            'key': 'fullname',
            'col':'',
            'actions':[]
        },
        {
            'label': 'Actions',
            'col':'auto',
            'key': 'actions',
            'actions':[]
        },
    ]
    
    return (
        <LeftSlideToggleIcon
            onReset={()=>{
                if(type==='group'){
                    dispatch({ type: 'SET_SRC_GROUP', payload: myGroup})
                    dispatch({ type: 'RESET_UPDATABLE'})
                }else{
                    dispatch({ type: 'RESET_USER'})
                    currentResource._id&&navigate('resources/assignment');
                }
            }}
            showResetButton={
                type==='group'?
                myGroup._id!==srcGroup._id
                :
                tgtUser._id
            }
            outline
            variant="secondary"
            label={type==='group'?
                srcGroup.name??'Switch '+type
                :
                tgtUser.fullname??'All users'
            }
            className="me-2"
            slideInContent={()=>
                <>
                    <div className="bg-secondary text-start py-3 col order-1">
                        <p className="m-0 margin-0">Switch {type}</p>
                    </div>
                    <div className="col-12 order-3 p-0">
                    <div className="p-2">
                        <Searchbar
                            type={type+'s'}
                        />
                    </div>
                    <RowContainer
                        target={type}
                        tableEntryTemplates={tableEntryTemplates}
                        extractFilter={(e)=>e.filter(item=>item.name===type.toString()+'s')[0].value}
                        childrenRowKey={type==='group'&&"children_groups"}
                        onLoad={(setEntries, filters)=>
                        type==='group'?
                            loadGroups(token, myGroup._id, filters, setEntries)
                            :
                            loadUser(token, srcGroup._id, filters, setEntries)
                        }
                    />
                    </div>
                </>
            }
        />

    )

}